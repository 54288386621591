.input_date{
  width: 100%;
}
.data-tables::-webkit-scrollbar {
  -webkit-appearance: none;
  }
  
  .data-tables::-webkit-scrollbar:vertical {
    width:10px;
  }
  
  .data-tables::-webkit-scrollbar-button:increment,.data-tables::-webkit-scrollbar-button {
    display: none;
  } 
  
  .data-tables::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  
  .data-tables::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  
  .data-tables::-webkit-scrollbar-track {
    border-radius: 10px;  
  }
  .my-swal {
    z-index: 999999 !important;
  }