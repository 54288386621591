.google-map {
    width: 100%;
    height: 60vh;
}

.pin {
    display: flex;
    align-items: center;
    width: 280px;
    color: var(--main-blue);
    /* word-wrap: break-word; */
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    font-size: 1.3em;
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 80vh;
    }

    .pin {
        width: 15vw;
    }

    .pin-icon {
        font-size: 10vw;
    }
}